.custom_popper {
  display: inline-flex;
}

.custom_popper:hover {
  cursor: pointer;
}

.custom_popper-wrapper {
  max-height: 160px;
  max-width: 500px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: auto;
  padding: 10px 0 0 0;
}

.custom_popper-wrapper li {
  list-style-type: none;
}

.custom_popper-wrapper::-webkit-scrollbar {
  width: 5px;
}

.custom_popper-wrapper::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

body div[id='transition-popper'] {
  z-index: 2000;
}
