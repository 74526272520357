.root {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top,
.middle,
.bottom {
  padding: 8px 0;
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
}

.root > div:not(:first-child) {
  border-top: 1px solid #e8eaed;
}
