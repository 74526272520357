.custom_popover {
  z-index: 100;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  box-shadow:
    0 -2px 6px rgba(0, 0, 0, 0.06),
    0 1px 2px rgba(0, 0, 0, 0.1),
    0 3px 7px rgba(0, 0, 0, 0.14);
  border: 1px solid;
  border-radius: 6px;
}
