.MuiModal-root .MuiCollapse-root {
  width: 100%;
}

body .MuiDialog-paper {
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.12),
    0 0 96px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
