.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 440px;
  padding: 24px;
  box-sizing: border-box;
}

.close_button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.close_button:hover {
  cursor: pointer;
}

.header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: -0.017em;
  margin: 0;
}

.buttons_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttons,
.list {
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
}

.buttons div:first-child {
  margin-right: 24px;
}
