.root,
.button {
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
  box-sizing: border-box;
}

.button {
  border: 1px solid;
  border-radius: 6px;
  padding: 9px;
  margin: 16px;
  height: 40px;
  min-width: 38px;
}

.icon svg {
  width: 20px;
  height: 20px;
  text-align: center;
}

.text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.006em;
  margin: 1.5px 0;
}
