.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 440px;
  padding: 24px;
  box-sizing: border-box;
}

.close_button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.close_button:hover {
  cursor: pointer;
}

.header {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.017em;
  margin: 0;
}

.root .text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  margin-top: 16px;
  margin-right: 48px;
  white-space: normal;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
  margin-top: 24px;
}

.buttons div:first-child {
  margin-right: 24px;
}

.list {
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
}

.list {
  max-height: 200px;
  overflow: auto;
}

.list li {
  white-space: unset;
  overflow-wrap: break-word;
}

.list_item {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  overflow: initial;
  display: list-item;
  margin: 0 20px 0 -20px;
}
