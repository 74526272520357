.line {
  height: 1px;
  margin: 16px 0;
}

.line,
.menu_item {
  width: -moz-available;
  /* For Mozzila */
  width: -webkit-fill-available;
  /* For Chrome */
  width: stretch;
  /* Unprefixed */
}

#custom-treeview .dx-treeview-item {
  padding: 0;
}

.menu_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.approval {
  justify-content: space-between;
}

.menu_item__count,
.approvals_icon__count {
  background: #ff0d33;
  border-radius: 100px;
  height: 16px;
  min-width: 16px;
  padding: 0 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.menu_item__wrapper {
  padding: 6px 16px 6px 24px;
}

.menu_item__text {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  user-select: none;
}

.menu_item__icon {
  width: 24px;
  height: 24px;
}

.menu_item__wrapper div {
  min-width: 40px;
}

.menu_item__wrapper:hover {
  cursor: pointer;
}

.approvals_icon {
  display: flex;
  position: relative;
}

.approvals_icon__count {
  position: absolute;
  top: -5px;
  left: 15px;
  z-index: 100;
}

.menu_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  min-width: 65px;
}

.versions {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  padding: 16px;
}

.versions-refresh_button:hover {
  cursor: pointer;
}
